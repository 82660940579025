<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">

      <!-- Brand logo-->
      <b-link class="brand-logo">
        <vuexy-logo />
        <h2 class="brand-text text-primary ml-1">
          سامانه مدیریت
        </h2>
      </b-link>
      <!-- /Brand logo-->

      <!-- Left Text-->
      <b-col
        lg="8"
        class="d-none d-lg-flex align-items-center p-5"
      >
        <div class="w-100 d-lg-flex align-items-center justify-content-center px-5">
          <b-img
            fluid
            :src="imgUrl"
            alt="Login V2"
          />
        </div>
      </b-col>
      <!-- /Left Text-->

      <!-- Login-->
      <b-col
        lg="4"
        class="d-flex align-items-center auth-bg px-2 p-lg-5"
      >
        <b-col
          sm="8"
          md="6"
          lg="12"
          class="px-xl-2 mx-auto"
        >
          <b-card-title
            title-tag="h2"
            class="font-weight-bold mb-1"
          >
            به سامانه مدیریتی ارز دیجیتال خوش آمدید 👋
          </b-card-title>
          <b-card-text class="mb-2">
            برای استفاده از سامانه ابتدا وارد شوید
          </b-card-text>

          <!-- form -->
          <!--          <validation-observer ref="loginValidation">-->
          <b-form
            class="auth-login-form mt-2"
            @submit.prevent="login"
          >
            <!-- email -->
            <b-form-group
              label="ایمیل"
              label-for="login-email"
            >
              <!--                <validation-provider-->
              <!--                  #default="{ errors }"-->
              <!--                  name="Email"-->
              <!--                  rules="required|email"-->
              <!--                >-->
              <b-form-input
                id="login-email"
                v-model="email"
                :state="!email ? false:null"
                placeholder="john@example.com"
              />
              <small class="text-danger">{{ emailError }}</small>
              <!--                </validation-provider>-->
            </b-form-group>

            <!-- forgot password -->
            <b-form-group>
              <!--                <div class="d-flex justify-content-between">-->
              <!--                  <label for="login-password">رمز عبور</label>-->
              <!--                  <b-link :to="{name:'auth-forgot-password-v2'}">-->
              <!--                    <small>Forgot Password?</small>-->
              <!--                  </b-link>-->
              <!--                </div>-->
              <!--                <validation-provider-->
              <!--                  #default="{ errors }"-->
              <!--                  name="Password"-->
              <!--                  rules="required"-->
              <!--                >-->
              <b-input-group
                class="input-group-merge"
                :class="!password ? 'is-invalid':null"
              >
                <b-form-input
                  id="login-password"
                  v-model="password"
                  :state="!password ? false:null"
                  class="form-control-merge"
                  :type="passwordFieldType"
                  name="login-password"
                  placeholder="············"
                />
                <b-input-group-append is-text>
                  <feather-icon
                    class="cursor-pointer"
                    :icon="passwordToggleIcon"
                    @click="togglePasswordVisibility"
                  />
                </b-input-group-append>
              </b-input-group>
              <small class="text-danger">{{ passError }}</small>
              <!--                </validation-provider>-->
            </b-form-group>

            <!-- checkbox -->
            <b-form-group>
              <b-form-checkbox
                id="remember-me"
                v-model="status"
                name="checkbox-1"
              >
                مرا به خاطر بسپار
              </b-form-checkbox>
            </b-form-group>

            <!-- submit buttons -->
            <b-button
              type="submit"
              variant="primary"
              block
            >
              ورود
            </b-button>
          </b-form>
          <!--          </validation-observer>-->

          <!--          <b-card-text class="text-center mt-2">-->
          <!--            <span>New on our platform? </span>-->
          <!--            <b-link :to="{name:'page-auth-register-v2'}">-->
          <!--              <span>&nbsp;Create an account</span>-->
          <!--            </b-link>-->
          <!--          </b-card-text>-->

          <!-- divider -->
          <!--          <div class="divider my-2">-->
          <!--            <div class="divider-text">-->
          <!--              or-->
          <!--            </div>-->
          <!--          </div>-->

          <!-- social buttons -->
          <!--          <div class="auth-footer-btn d-flex justify-content-center">-->
          <!--            <b-button-->
          <!--              variant="facebook"-->
          <!--              href="javascript:void(0)"-->
          <!--            >-->
          <!--              <feather-icon icon="FacebookIcon" />-->
          <!--            </b-button>-->
          <!--            <b-button-->
          <!--              variant="twitter"-->
          <!--              href="javascript:void(0)"-->
          <!--            >-->
          <!--              <feather-icon icon="TwitterIcon" />-->
          <!--            </b-button>-->
          <!--            <b-button-->
          <!--              variant="google"-->
          <!--              href="javascript:void(0)"-->
          <!--            >-->
          <!--              <feather-icon icon="MailIcon" />-->
          <!--            </b-button>-->
          <!--            <b-button-->
          <!--              variant="github"-->
          <!--              href="javascript:void(0)"-->
          <!--            >-->
          <!--              <feather-icon icon="GithubIcon" />-->
          <!--            </b-button>-->
          <!--          </div>-->
        </b-col>
      </b-col>
    <!-- /Login-->
    </b-row>
  </div>
</template>

<script>
/* eslint-disable global-require */
// import { ValidationProvider, ValidationObserver } from 'vee-validate'
import VuexyLogo from '@core/layouts/components/Logo.vue'
import {
  BRow, BCol, BLink, BFormGroup, BFormInput, BInputGroupAppend, BInputGroup, BFormCheckbox, BCardText, BCardTitle, BImg, BForm, BButton,
} from 'bootstrap-vue'
// import { required, email } from '@validations'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
// import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import store from '@/store/index'

export default {
  components: {
    BRow,
    BCol,
    BLink,
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BInputGroup,
    BFormCheckbox,
    BCardText,
    BCardTitle,
    BImg,
    BForm,
    BButton,
    VuexyLogo,
    // ValidationProvider,
    // ValidationObserver,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      status: '',
      password: '',
      userEmail: '',
      sideImg: require('@/assets/images/pages/login-v2.svg'),
      // validation rulesimport store from '@/store/index'
      show: false,
      email: '',
      emailError: '',
      passError: '',
      res: '',
      errorMessage: '',
      OSName: 'Unknown',
      borwserType: '',
      deviceType: '',
      showLoading: false,
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sideImg = require('@/assets/images/pages/login-v2-dark.svg')
        return this.sideImg
      }
      return this.sideImg
    },
  },
  methods: {
    // eslint-disable-next-line consistent-return
    checkEmail() {
      if (!this.email) {
        this.emailError = 'لطفا ایمیل را وارد کنید'
      } else if (!this.validEmail(this.email)) {
        this.emailError = 'ایمیل وارد شده صحیح نمیباشد'
      } else {
        this.emailError = ''
      }
      if (!this.password) {
        this.passError = 'لطفا رمز عبور را وارد نمایید'
      } else {
        this.passError = ''
      }
      if (!this.emailError.length && !this.passError.length) {
        return true
      }
    },
    // eslint-disable-next-line no-shadow
    validEmail(email) {
      const re = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/
      return re.test(email)
    },
    receive(e) {
      this.email = e
      this.checkEmail(e)
    },
    receive1(e) {
      this.password = e
    },
    login() {
      // console.log(this.axios)
      if (this.checkEmail()) {
        const data = {
          email: this.email,
          password: this.password,
        }
        this.showLoading = true
        this.$axios({
          url: '/admins/login',
          method: 'POST',
          data,
        })
          .then(response => {
            this.res = response.data
            if (response.data.status) {
              localStorage.token = `Bearer ${this.res.token}`
              this.state.token = localStorage.token
              this.$axios.defaults.headers.common.Authorization = this.state.token
              this.$router.push('/')
            }
          }).catch(error => {
            this.showLoading = false
            if (error.response) {
              if (error.response.data.status === 'UNAUTHORIZED') {
                this.errorMessage = 'نام کاربری یا رمز عبور اشتباه است'
              } else if (error.response.data.status === 'NOT_FOUND') {
                this.errorMessage = 'نام کاربری یافت نشد.'
              }
            }
          })
      }
    },
    // validationForm() {
    //   this.$refs.loginValidation.validate().then(success => {
    //     if (success) {
    //       this.$toast({
    //         component: ToastificationContent,
    //         props: {
    //           title: 'Form Submitted',
    //           icon: 'EditIcon',
    //           variant: 'success',
    //         },
    //       })
    //     }
    //   })
    // },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
